<template>
    <div class="content">
<!--        <div class="beian-con">Copyright © 2023-2024<span class="company-name">{{companyName}}</span>保留所有权利 <a
                                href="https://beian.miit.gov.cn" class="beian">{{record_no}}</a></div> -->
		   <div class="beian-con">Copyright © 2023-2024<span class="company-name">深圳市宏木龙技术有限公司</span>保留所有权利 <a
									href="https://beian.miit.gov.cn" class="beian">粤ICP备2024309204号</a></div>
    </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
    setup(){
        const store = useStore()
        const companyName = computed(()=>{
            return store.state.companyName
        })
        const record_no = computed(()=>{
            return store.state.record_num
        })
        return{
            companyName,
            record_no
        }
    }
}
</script>
<style scoped lang="scss">
    .content{
        width: 100%;
        height: 154px;
        display: flex;
        align-items: center;
        justify-content: center;
        .beian-con{
            color: #000B33;
            position: relative;
            margin-top: 38px;
            a{
                text-decoration: none;
                color: #000B33;
            }
        }
        .beian-con:after{
            content: '';
            width: 1200px;
            height: 1px;
            background: #000B33;
            opacity: 0.15;
            position: absolute;
            top: -30px;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
</style>